import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './gesendetbtn.css'

const Gesendetbtn = (props) => {
  return (
    <a href="/#kontakt">
      <div id={props.gesendetbtnId} className="gesendetbtn-container">
        <button type="button" className="gesendetbtn-button thq-button-filled">
          <span>
            {props.text ?? (
              <Fragment>
                <span className="gesendetbtn-text2">Zurück Zur Seite</span>
              </Fragment>
            )}
          </span>
        </button>
      </div>
    </a>
  )
}

Gesendetbtn.defaultProps = {
  gesendetbtnId: '',
  text: undefined,
}

Gesendetbtn.propTypes = {
  gesendetbtnId: PropTypes.string,
  text: PropTypes.element,
}

export default Gesendetbtn
