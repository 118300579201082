import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Gesendetbtn from '../components/gesendetbtn'
import './gesendet.css'

const Gesendet = (props) => {
  return (
    <div className="gesendet-container1">
      <Helmet>
        <title>Gesendet! - Ferienwohnung Costa Blanca</title>
        <meta
          name="description"
          content="Unsere Ferienwohnung in Portico Mar an der Costa Blanca bietet Ihnen auf 80 m² eine umfangreiche Ausstattung, die auch längere Aufenthalte angenehm gestaltet."
        />
        <meta
          property="og:title"
          content="Gesendet! - Ferienwohnung Costa Blanca"
        />
        <meta
          property="og:description"
          content="Unsere Ferienwohnung in Portico Mar an der Costa Blanca bietet Ihnen auf 80 m² eine umfangreiche Ausstattung, die auch längere Aufenthalte angenehm gestaltet."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/54bdcf4b-f76c-48c3-af25-f6e30758d129/bd0be4d1-57fe-4925-af91-db0a30fbb041?org_if_sml=1&amp;force_format=original"
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="gesendet-container2">
        <img
          alt="image"
          src="/noun-success-2617430.svg"
          className="gesendet-image"
        />
        <span className="gesendet-text1">Erfolgreich Gesendet!</span>
        <Gesendetbtn
          text={
            <Fragment>
              <span className="gesendet-text2">Zurück zur Seite</span>
            </Fragment>
          }
          gesendetbtnId="gesendetbtn"
        ></Gesendetbtn>
      </div>
    </div>
  )
}

export default Gesendet
