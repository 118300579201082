import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './contact-form.css'

const ContactForm = (props) => {
  const [send, setSend] = useState(false)
  return (
    <div id="kontakt" className="contact-form-contact11 thq-section-padding">
      <div className="contact-form-max-width thq-section-max-width">
        <div className="contact-form-content1">
          <form
            action="send_email.php"
            method="POST"
            className="contact-form-form thq-card"
          >
            <h1>
              {props.heading1 ?? (
                <Fragment>
                  <h1 className="contact-form-text19">Kontaktformular</h1>
                </Fragment>
              )}
            </h1>
            <span>
              {props.text1 ?? (
                <Fragment>
                  <span className="contact-form-text23">
                    Nutzen sie das Kontaktformular oder Schreiben uns eine
                    E-Mail an: Kontakt@fewo.de
                  </span>
                </Fragment>
              )}
            </span>
            <div className="contact-form-container1">
              <div className="contact-form-container2">
                <div className="contact-form-container3">
                  <div className="contact-form-container4">
                    <div className="contact-form-input1">
                      <label
                        htmlFor="contact-form-11-first-name"
                        className="thq-body-small"
                      >
                        Vorname
                      </label>
                      <input
                        type="text"
                        id="Vorname"
                        name="Vorname"
                        rows="vorname"
                        placeholder="Vorname"
                        className="thq-input"
                      />
                    </div>
                    <div className="contact-form-input2">
                      <label
                        htmlFor="contact-form-11-last-name"
                        className="thq-body-small"
                      >
                        Nachname*
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="Nachname"
                        required="true"
                        placeholder="Nachname"
                        className="thq-input"
                      />
                    </div>
                  </div>
                  <div className="contact-form-input3">
                    <label
                      htmlFor="contact-form-11-email"
                      className="thq-body-small"
                    >
                      E-Mail Adresse*
                    </label>
                    <input
                      type="text"
                      id="Email"
                      name="Email"
                      required="true"
                      placeholder="E-Mail"
                      className="contact-form-text-input3 thq-input"
                    />
                  </div>
                </div>
                <div className="contact-form-container5">
                  <div className="contact-form-content2">
                    <svg
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      className="contact-form-icon1 thq-icon-small"
                    >
                      <path
                        d="M16.996 7.686a2 2 0 0 1 1.749 1.03l2.446 4.406a2 2 0 0 1 .04 1.865l-2.356 4.714s.683 3.511 3.541 6.37c2.859 2.858 6.358 3.53 6.358 3.53l4.713-2.357a2 2 0 0 1 1.866.04l4.42 2.458A2 2 0 0 1 40.8 31.49v5.073c0 2.584-2.4 4.45-4.848 3.624c-5.028-1.697-12.833-4.927-17.78-9.874c-4.946-4.947-8.177-12.751-9.873-17.78c-.826-2.447 1.04-4.847 3.624-4.847z"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="4"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <div className="contact-form-contact-info1">
                      <h3 className="thq-heading-3">Telefon</h3>
                      <span>
                        {props.email ?? (
                          <Fragment>
                            <span className="contact-form-text24 thq-body-small">
                              +49 (0) 517 0173 899
                            </span>
                          </Fragment>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="contact-form-content3">
                    <svg
                      viewBox="0 0 1024 1024"
                      className="contact-form-icon3 thq-icon-small"
                    >
                      <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                    </svg>
                    <div className="contact-form-contact-info2">
                      <h3 className="thq-heading-3">E-Mail Adresse</h3>
                      <span>
                        {props.email1 ?? (
                          <Fragment>
                            <span className="contact-form-text21 thq-body-small">
                              kontakt@fewo.de
                            </span>
                          </Fragment>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-form-input4">
              <label
                htmlFor="contact-form-11-message"
                className="thq-body-small"
              >
                Nachricht*
              </label>
              <textarea
                id="Nachricht"
                name="Nachricht"
                rows="6"
                placeholder="Nachricht eintippen"
                className="thq-input"
              ></textarea>
              <span>
                {props.text2 ?? (
                  <Fragment>
                    <span className="contact-form-text20">
                      *Erforderliche Felder
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
            <div className="contact-form-container6">
              <button
                name="submit"
                type="submit"
                onClick={() => setSend(true)}
                className="thq-button-filled"
              >
                <span>
                  {props.submitAction ?? (
                    <Fragment>
                      <span className="contact-form-text22 thq-body-small">
                        Nachricht Senden
                      </span>
                    </Fragment>
                  )}
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

ContactForm.defaultProps = {
  heading1: undefined,
  text2: undefined,
  email1: undefined,
  submitAction: undefined,
  text1: undefined,
  email: undefined,
}

ContactForm.propTypes = {
  heading1: PropTypes.element,
  text2: PropTypes.element,
  email1: PropTypes.element,
  submitAction: PropTypes.element,
  text1: PropTypes.element,
  email: PropTypes.element,
}

export default ContactForm
