import React, { useState, Fragment } from 'react'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './ausstattung.css'

const Ausstattung = (props) => {
  const [galerie, setGalerie] = useState(false)
  return (
    <div
      id="ausstattung"
      className={`ausstattung-container1 thq-section-padding ${props.rootClassName} `}
    >
      <div className="ausstattung-max-width thq-flex-column thq-section-max-width">
        <div className="ausstattung-container2">
          <h1>
            {props.heading ?? (
              <Fragment>
                <h1 className="ausstattung-text25 thq-heading-2">
                  Ausstattung
                </h1>
              </Fragment>
            )}
          </h1>
          <div className="ausstattung-content thq-grid-2">
            <img
              alt={props.imageAlt}
              src="/Icons/noun-bedroom-5156534.svg"
              className="ausstattung-image10 thq-icon-small thq-icon-medium"
            />
            <span className="gridfix">2 Schlafzimmer max. 5 Personen</span>
            <img
              alt={props.imageAlt1}
              src="/Icons/noun-air-conditioner-5156529.svg"
              className="ausstattung-image11 thq-icon-small thq-icon-medium"
            />
            <span className="gridfix">
              Ein Schlafzimmer mit Doppelbett und Klimaanlage. Balkon mit
              Meerblick
            </span>
            <img
              alt={props.imageAlt14}
              src="/Icons/noun-kitchen-5156548.svg"
              className="ausstattung-image12 thq-icon-small thq-icon-medium"
            />
            <span className="gridfix">
              Voll eingerichtete Küche mit Spülmaschine und Gefrierschrank
            </span>
            <img
              alt={props.imageAlt18}
              src="/Icons/noun-bathroom-6481768.svg"
              className="ausstattung-image13 thq-icon-small thq-icon-medium"
            />
            <span className="gridfix">Vollwertiges Badezimmer</span>
            <img
              alt={props.imageAlt17}
              src="/Icons/noun-toilet-7246831.svg"
              className="ausstattung-image14 thq-icon-small thq-icon-medium"
            />
            <span className="gridfix">Zusätzliche Gästetoilette</span>
            <img
              alt={props.imageAlt16}
              src="/Icons/noun-laundry-5156532.svg"
              className="ausstattung-image15 thq-icon-small thq-icon-medium"
            />
            <span className="gridfix">Waschmaschine und Wäscheständer</span>
            <img
              alt={props.imageAlt15}
              src="/Icons/noun-laundry-7181800.svg"
              className="ausstattung-image16 thq-icon-small thq-icon-medium"
            />
            <span className="gridfix">Handtücher und Bettwäsche</span>
            <img
              alt={props.imageAlt13}
              src="/Icons/noun-high-chair-6984018.svg"
              className="ausstattung-image17 thq-icon-small thq-icon-medium"
            />
            <span className="gridfix">Kinderstuhl, Babybett, Föhn</span>
            <img
              alt={props.imageAlt12}
              src="/Icons/noun-wlan-2013897.svg"
              className="ausstattung-image18 thq-icon-small thq-icon-medium"
            />
            <span className="gridfix">WLAN, Flachbild-TV</span>
            <img
              alt={props.imageAlt11}
              src="/Icons/noun-pool-7183779.svg"
              className="ausstattung-image19 thq-icon-small thq-icon-medium"
            />
            <span className="gridfix">Direkter Zugang zum Pool</span>
            <img
              alt={props.imageAlt112}
              src="/Icons/noun-sea-1755215.svg"
              className="ausstattung-image20 thq-icon-small thq-icon-medium"
            />
            <span className="gridfix">Terrasse mit Meerblick</span>
            <img
              alt={props.imageAlt111}
              src="/Icons/noun-car-parking-5446083.svg"
              className="ausstattung-image21 thq-icon-small thq-icon-medium"
            />
            <span className="gridfix">Tiefgaragen-Stellplatz inklusive</span>
          </div>
        </div>
        <div className="ausstattung-container3">
          <div
            onClick={() => setGalerie(true)}
            className="ausstattung-container4"
          >
            <svg
              width="56"
              height="56"
              viewBox="0 0 56 56"
              className="ausstattung-icon1 thq-icon-small"
            >
              <path
                d="m30.961 28l11.88 11.88v-5.526a2.08 2.08 0 1 1 4.159 0v10.398l-.001.074a2.07 2.07 0 0 1-.608 1.544a2.07 2.07 0 0 1-1.3.602q-.165.028-.339.028H34.354a2.08 2.08 0 1 1 0-4.16h5.567l-11.9-11.9l-11.9 11.9h5.525a2.08 2.08 0 1 1 0 4.16H11.248l-.074-.001a2.07 2.07 0 0 1-1.544-.608a2.07 2.07 0 0 1-.602-1.3A2 2 0 0 1 9 44.752V34.354a2.08 2.08 0 1 1 4.16 0v5.567L25.08 28L13.16 16.08v5.566a2.08 2.08 0 1 1-4.16 0V11.248q0-.174.028-.34c.038-.473.239-.936.602-1.299a2.07 2.07 0 0 1 1.544-.608L11.248 9h10.398a2.08 2.08 0 1 1 0 4.16H16.12l11.9 11.9l11.9-11.9h-5.567a2.08 2.08 0 1 1 0-4.16h10.398q.174 0 .34.028c.473.038.936.239 1.299.602c.425.424.627.987.608 1.544l.001.074v10.398a2.08 2.08 0 1 1-4.16 0V16.12z"
                fill="currentColor"
                fill-rule="evenodd"
              ></path>
            </svg>
            <span>
              {props.text1 ?? (
                <Fragment>
                  <span className="ausstattung-text27"> Vollbild Ansicht</span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="ausstattung-container5">
            <div
              data-thq="slider"
              data-autoplay="false"
              data-navigation="true"
              data-pagination="false"
              className="ausstattung-slider1 swiper"
            >
              <div data-thq="slider-wrapper" className="swiper-wrapper">
                <div
                  data-thq="slider-slide"
                  className="ausstattung-slider-slide10 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="ausstattung-slider-slide11 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="ausstattung-slider-slide12 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="ausstattung-slider-slide13 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="ausstattung-slider-slide14 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="ausstattung-slider-slide15 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="ausstattung-slider-slide16 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="ausstattung-slider-slide17 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="ausstattung-slider-slide18 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="ausstattung-slider-slide19 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="ausstattung-slider-slide20 swiper-slide"
                ></div>
                <div
                  data-thq="slider-slide"
                  className="ausstattung-slider-slide21 swiper-slide"
                ></div>
              </div>
              <div
                data-thq="slider-pagination"
                className="ausstattung-slider-pagination1 swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
              >
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet swiper-pagination-bullet-active"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
              </div>
              <div
                data-thq="slider-button-prev"
                className="ausstattung-slider-button-prev1 swiper-button-prev"
              ></div>
              <div
                data-thq="slider-button-next"
                className="ausstattung-slider-button-next1 swiper-button-next"
              ></div>
            </div>
          </div>
          <div className="ausstattung-container6">
            <span>
              {props.text2 ?? (
                <Fragment>
                  <span className="ausstattung-text26 thq-heading-3">
                    Ansichtskarte Wohnkomplex &quot;Portico Mar&quot;
                  </span>
                </Fragment>
              )}
            </span>
            <img
              alt={props.imageAlt3}
              src={props.imageSrc2}
              className="ausstattung-image22"
            />
          </div>
        </div>
      </div>
      {galerie && (
        <div className="ausstattung-container7">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            onClick={() => setGalerie(false)}
            className="ausstattung-icon3 thq-icon-medium"
          >
            <path
              d="M20 20L4 4m16 0L4 20"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
            ></path>
          </svg>
          <div
            data-thq="slider"
            data-autoplay="false"
            data-navigation="true"
            data-pagination="false"
            className="ausstattung-slider2 swiper"
          >
            <div data-thq="slider-wrapper" className="swiper-wrapper">
              <div
                data-thq="slider-slide"
                className="ausstattung-slider-slide22 swiper-slide"
              ></div>
              <div
                data-thq="slider-slide"
                className="ausstattung-slider-slide23 swiper-slide"
              ></div>
              <div
                data-thq="slider-slide"
                className="ausstattung-slider-slide24 swiper-slide"
              ></div>
              <div
                data-thq="slider-slide"
                className="ausstattung-slider-slide25 swiper-slide"
              ></div>
              <div
                data-thq="slider-slide"
                className="ausstattung-slider-slide26 swiper-slide"
              ></div>
              <div
                data-thq="slider-slide"
                className="ausstattung-slider-slide27 swiper-slide"
              ></div>
              <div
                data-thq="slider-slide"
                className="ausstattung-slider-slide28 swiper-slide"
              ></div>
              <div
                data-thq="slider-slide"
                className="ausstattung-slider-slide29 swiper-slide"
              ></div>
              <div
                data-thq="slider-slide"
                className="ausstattung-slider-slide30 swiper-slide"
              ></div>
              <div
                data-thq="slider-slide"
                className="ausstattung-slider-slide31 swiper-slide"
              ></div>
              <div
                data-thq="slider-slide"
                className="ausstattung-slider-slide32 swiper-slide"
              ></div>
              <div
                data-thq="slider-slide"
                className="ausstattung-slider-slide33 swiper-slide"
              ></div>
            </div>
            <div
              data-thq="slider-pagination"
              className="ausstattung-slider-pagination2 swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
            >
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet swiper-pagination-bullet-active"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
            </div>
            <div
              data-thq="slider-button-prev"
              className="ausstattung-slider-button-prev2 swiper-button-prev"
            ></div>
            <div
              data-thq="slider-button-next"
              className="ausstattung-slider-button-next2 swiper-button-next"
            ></div>
          </div>
          <div>
            <div className="ausstattung-container9">
              <Script
                html={`<script defer>
  (function() {
    const sliderScript = document.createElement('script');
    sliderScript.setAttribute('type', 'module');
    sliderScript.setAttribute('defer', 'true');
    sliderScript.innerHTML = \`import { Slider } from 'https://unpkg.com/@teleporthq/teleport-custom-scripts/dist/components.mjs';
    new Slider().init();\`;

    document.body.appendChild(sliderScript);
  })();
</script>`}
              ></Script>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

Ausstattung.defaultProps = {
  heading: undefined,
  imageAlt1: 'image',
  imageAlt13: 'image',
  text2: undefined,
  imageAlt12: 'image',
  imageAlt14: 'image',
  imageAlt: 'image',
  imageSrc2: '/porticomar.svg',
  text1: undefined,
  imageAlt112: 'image',
  imageAlt17: 'image',
  imageAlt111: 'image',
  imageAlt15: 'image',
  imageAlt16: 'image',
  imageAlt11: 'image',
  rootClassName: '',
  imageAlt3: 'image',
  imageAlt18: 'image',
}

Ausstattung.propTypes = {
  heading: PropTypes.element,
  imageAlt1: PropTypes.string,
  imageAlt13: PropTypes.string,
  text2: PropTypes.element,
  imageAlt12: PropTypes.string,
  imageAlt14: PropTypes.string,
  imageAlt: PropTypes.string,
  imageSrc2: PropTypes.string,
  text1: PropTypes.element,
  imageAlt112: PropTypes.string,
  imageAlt17: PropTypes.string,
  imageAlt111: PropTypes.string,
  imageAlt15: PropTypes.string,
  imageAlt16: PropTypes.string,
  imageAlt11: PropTypes.string,
  rootClassName: PropTypes.string,
  imageAlt3: PropTypes.string,
  imageAlt18: PropTypes.string,
}

export default Ausstattung
