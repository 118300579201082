import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <footer className="footer-footer4 thq-section-padding">
      <div className="footer-max-width thq-section-max-width">
        <div className="footer-content">
          <div className="footer-logo">
            <img
              alt={props.logoAlt}
              src={props.logoSrc}
              className="footer-image1"
            />
          </div>
        </div>
        <div className="footer-credits">
          <div className="thq-divider-horizontal"></div>
          <div className="footer-row">
            <div className="footer-footer-links">
              <span className="thq-body-small">© 2024 FeWo-Costa-Blanca</span>
              <Link to="/impressum" className="footer-link11">
                {props.privacyLink ?? (
                  <Fragment>
                    <span className="footer-text thq-body-small">
                      Impressum
                    </span>
                  </Fragment>
                )}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  logoAlt: 'logo',
  privacyLink: undefined,
  logoSrc: '/v4.svg',
}

Footer.propTypes = {
  logoAlt: PropTypes.string,
  privacyLink: PropTypes.element,
  logoSrc: PropTypes.string,
}

export default Footer
