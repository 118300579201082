import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './cta.css'

const CTA = (props) => {
  return (
    <div id="cb" className="cta-container thq-section-padding">
      <img
        alt={props.backgroundImageAlt}
        src={props.backgroundImageSrc}
        className="cta-background-image thq-img-ratio-16-9"
      />
      <div className="cta-max-width thq-section-max-width">
        <div className="cta-column">
          <div className="cta-content">
            <h2>
              {props.heading1 ?? (
                <Fragment>
                  <h2 className="cta-text3 thq-heading-2 shadowhand">
                    Die Costa Blanca erwartet Sie
                  </h2>
                </Fragment>
              )}
            </h2>
          </div>
          <div className="cta-actions">
            <animate-on-reveal
              animation="zoomIn"
              duration="500ms"
              delay="0s"
              direction="normal"
              easing="ease"
              iteration="1"
            >
              <a
                href="#kontakt"
                data-thq-animate-on-reveal="true"
                className="cta-link thq-button-filled"
              >
                <span>
                  {props.action1 ?? (
                    <Fragment>
                      <span className="cta-text4">Action1</span>
                    </Fragment>
                  )}
                </span>
              </a>
            </animate-on-reveal>
          </div>
        </div>
      </div>
    </div>
  )
}

CTA.defaultProps = {
  heading1: undefined,
  backgroundImageAlt: 'image',
  action1: undefined,
  backgroundImageSrc:
    'https://images.unsplash.com/photo-1550895030-823330fc2551?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDI2N3x8YWJzdHJhY3R8ZW58MHx8fHwxNzEzOTQ2MzU0fDA&ixlib=rb-4.0.3&w=1500',
}

CTA.propTypes = {
  heading1: PropTypes.element,
  backgroundImageAlt: PropTypes.string,
  action1: PropTypes.element,
  backgroundImageSrc: PropTypes.string,
}

export default CTA
