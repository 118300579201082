import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './hero4.css'

const Hero4 = (props) => {
  return (
    <div className={`hero4-header13 ${props.rootClassName} `}>
      <div className="hero4-container1">
        <img
          alt={props.imageAlt6}
          src={props.imageSrc6}
          className="hero4-image10"
        />
      </div>
      <video
        id="herovid"
        src={props.video1Src}
        loop="true"
        muted="true"
        poster={props.video1Poster}
        autoPlay="true"
        playsInline="true"
        className="hero4-video"
      ></video>
      <div className="hero4-content thq-section-padding">
        <div className="hero4-max-width thq-section-max-width thq-flex-row">
          <div className="hero4-column1">
            <h1>
              {props.heading1 ?? (
                <Fragment>
                  <h1 className="hero4-text7 thq-heading-1 shadowhandW">
                    Ferienwohnung mit Meerblick
                  </h1>
                </Fragment>
              )}
            </h1>
            <div className="hero4-container2">
              <img
                alt={props.imageAlt}
                src={props.imageSrc}
                className="hero4-image11 thq-icon-small"
              />
              <img
                alt={props.imageAlt1}
                src={props.imageSrc1}
                className="hero4-image12 thq-icon-small"
              />
              <img
                alt={props.imageAlt2}
                src={props.imageSrc2}
                className="hero4-image13 thq-icon-small"
              />
              <img
                alt={props.imageAlt3}
                src={props.imageSrc3}
                className="hero4-image14 thq-icon-small"
              />
              <img
                alt={props.imageAlt4}
                src={props.imageSrc4}
                className="hero4-image15 thq-icon-small"
              />
            </div>
          </div>
          <div className="hero4-column2">
            <p>
              {props.content1 ?? (
                <Fragment>
                  <p className="hero4-text6 thq-body-large">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla,
                    ut commodo diam libero vitae erat.
                  </p>
                </Fragment>
              )}
            </p>
            <div className="hero4-actions">
              <a href="#cb" className="hero4-button1 thq-button-filled">
                <span>
                  {props.action1 ?? (
                    <Fragment>
                      <span className="hero4-text8 thq-body-small">
                        Main action
                      </span>
                    </Fragment>
                  )}
                </span>
              </a>
              <a
                href="#ausstattung"
                className="hero4-button2 thq-button-outline"
              >
                <span>
                  {props.action2 ?? (
                    <Fragment>
                      <span className="hero4-text5 thq-body-small">
                        Secondary action
                      </span>
                    </Fragment>
                  )}
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="hero4-container3">
          <img
            alt={props.imageAlt5}
            src={props.imageSrc5}
            className="hero4-image16 thq-icon-small"
          />
          <img
            alt={props.imageAlt11}
            src={props.imageSrc11}
            className="hero4-image17 thq-icon-small"
          />
          <img
            alt={props.imageAlt21}
            src={props.imageSrc21}
            className="hero4-image18 thq-icon-small"
          />
          <img
            alt={props.imageAlt31}
            src={props.imageSrc31}
            className="hero4-image19 thq-icon-small"
          />
          <img
            alt={props.imageAlt41}
            src={props.imageSrc41}
            className="hero4-image20 thq-icon-small"
          />
        </div>
        <div className="hero4-container4"></div>
      </div>
      <a href="#pricing" className="hero4-link">
        <img
          alt={props.imageAlt7}
          src={props.imageSrc7}
          className="hero4-image21"
        />
      </a>
    </div>
  )
}

Hero4.defaultProps = {
  imageSrc2: '/Icons/noun-star-7202171.svg',
  imageAlt1: 'image',
  imageAlt3: 'image',
  video1Src: '/herovideo2.mp4',
  imageSrc1: '/Icons/noun-star-7202171.svg',
  imageSrc41: '/Icons/noun-star-7202171.svg',
  imageAlt4: 'image',
  action2: undefined,
  imageAlt21: 'image',
  imageSrc3: '/Icons/noun-star-7202171.svg',
  imageSrc4: '/Icons/noun-star-7202171.svg',
  imageSrc6: '/v4.svg',
  imageSrc11: '/Icons/noun-star-7202171.svg',
  video1Poster: 'https://play.teleporthq.io/static/svg/videoposter.svg',
  imageAlt6: 'image',
  imageAlt11: 'image',
  imageAlt41: 'image',
  imageSrc: '/Icons/noun-star-7202171.svg',
  imageAlt: 'image',
  imageSrc31: '/Icons/noun-star-7202171.svg',
  imageSrc5: '/Icons/noun-star-7202171.svg',
  content1: undefined,
  rootClassName: '',
  imageAlt31: 'image',
  imageSrc21: '/Icons/noun-star-7202171.svg',
  imageAlt5: 'image',
  heading1: undefined,
  imageAlt7: 'image',
  action1: undefined,
  imageSrc7: '/noun-scroll-down-4240946.svg',
  imageAlt2: 'image',
}

Hero4.propTypes = {
  imageSrc2: PropTypes.string,
  imageAlt1: PropTypes.string,
  imageAlt3: PropTypes.string,
  video1Src: PropTypes.string,
  imageSrc1: PropTypes.string,
  imageSrc41: PropTypes.string,
  imageAlt4: PropTypes.string,
  action2: PropTypes.element,
  imageAlt21: PropTypes.string,
  imageSrc3: PropTypes.string,
  imageSrc4: PropTypes.string,
  imageSrc6: PropTypes.string,
  imageSrc11: PropTypes.string,
  video1Poster: PropTypes.string,
  imageAlt6: PropTypes.string,
  imageAlt11: PropTypes.string,
  imageAlt41: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  imageSrc31: PropTypes.string,
  imageSrc5: PropTypes.string,
  content1: PropTypes.element,
  rootClassName: PropTypes.string,
  imageAlt31: PropTypes.string,
  imageSrc21: PropTypes.string,
  imageAlt5: PropTypes.string,
  heading1: PropTypes.element,
  imageAlt7: PropTypes.string,
  action1: PropTypes.element,
  imageSrc7: PropTypes.string,
  imageAlt2: PropTypes.string,
}

export default Hero4
