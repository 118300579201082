import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './impressum.css'

const Impressum = (props) => {
  return (
    <div className="impressum-container">
      <Helmet>
        <title>Ferienwohnung Costa Blanca - Impressum</title>
        <meta
          name="description"
          content="Unsere Ferienwohnung in Portico Mar an der Costa Blanca bietet Ihnen auf 80 m² eine umfangreiche Ausstattung, die auch längere Aufenthalte angenehm gestaltet."
        />
        <meta
          property="og:title"
          content="Ferienwohnung Costa Blanca - Impressum"
        />
        <meta
          property="og:description"
          content="Unsere Ferienwohnung in Portico Mar an der Costa Blanca bietet Ihnen auf 80 m² eine umfangreiche Ausstattung, die auch längere Aufenthalte angenehm gestaltet."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/54bdcf4b-f76c-48c3-af25-f6e30758d129/bd0be4d1-57fe-4925-af91-db0a30fbb041?org_if_sml=1&amp;force_format=original"
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <span>
        <span>Sabine Herzschuh</span>
        <br></br>
        <span>Kurze Straße 7</span>
        <br></br>
        <span>28844 Weyhe</span>
        <br></br>
        <br></br>
        <span>
          <span
            dangerouslySetInnerHTML={{
              __html: ' ',
            }}
          />
        </span>
        <br></br>
        <br></br>
        <span>E-Mail: Fewo_PorticoMar@web.de</span>
        <br></br>
        <span>Tel.: +49 1517 0173899</span>
        <br></br>
      </span>
      <Link to="/" className="impressum-navlink thq-button-filled button">
        Zurück zur Startseite
      </Link>
    </div>
  )
}

export default Impressum
