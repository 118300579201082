import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './kernargumente.css'

const Kernargumente = (props) => {
  return (
    <div id="entdecken" className="kernargumente-layout349 thq-section-padding">
      <div className="kernargumente-max-width thq-section-max-width">
        <div className="kernargumente-container1">
          <h2>
            {props.heading1 ?? (
              <Fragment>
                <h2 className="kernargumente-text33 thq-heading-2">Discover</h2>
              </Fragment>
            )}
          </h2>
          <span>
            {props.content1 ?? (
              <Fragment>
                <span className="kernargumente-text24 thq-body-small">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse varius enim in eros elementum tristique. Duis
                  cursus, mi quis viverra ornare, eros dolor interdum nulla.
                </span>
              </Fragment>
            )}
          </span>
        </div>
        <div className="thq-grid-3">
          <div className="kernargumente-container3 thq-card">
            <img
              alt={props.feature1ImageAlt}
              src={props.feature1ImageSrc}
              className="kernargumente-image1"
            />
            <h2>
              {props.feature1Title ?? (
                <Fragment>
                  <h2 className="kernargumente-text31 thq-heading-2">
                    Feature 1
                  </h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.feature1Description ?? (
                <Fragment>
                  <span className="kernargumente-text36 thq-body-small">
                    <span>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse varius enim in eros elementum tristique. Duis
                      cursus, mi quis viverra ornare, eros dolor interdum nulla.
                    </span>
                    <br></br>
                    <span>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse varius enim in eros elementum tristique. Duis
                      cursus, mi quis viverra ornare, eros dolor interdum nulla.
                    </span>
                    <br></br>
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="kernargumente-container4 thq-card">
            <img
              alt={props.feature2ImageAlt}
              src={props.feature2ImageSrc}
              className="kernargumente-image2"
            />
            <h2>
              {props.feature2Title ?? (
                <Fragment>
                  <h2 className="kernargumente-text28 thq-heading-2">
                    Feature 2
                  </h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.feature2Description ?? (
                <Fragment>
                  <span className="kernargumente-text25 thq-body-small">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="kernargumente-container5 thq-card">
            <img
              alt={props.feature3ImageAlt}
              src={props.feature3ImageSrc}
              className="kernargumente-image3"
            />
            <h2>
              {props.feature3Title ?? (
                <Fragment>
                  <h2 className="kernargumente-text29 thq-heading-2">
                    Feature 3
                  </h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.feature3Description ?? (
                <Fragment>
                  <span className="kernargumente-text26 thq-body-small">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="kernargumente-container6 thq-card">
            <img
              alt={props.feature4ImageAlt}
              src={props.feature4ImageSrc}
              className="kernargumente-image4"
            />
            <h2>
              {props.feature4Title ?? (
                <Fragment>
                  <h2 className="kernargumente-text41 thq-heading-2">
                    Feature 4
                  </h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.feature4Description ?? (
                <Fragment>
                  <span className="kernargumente-text27 thq-body-small">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="kernargumente-container7 thq-card">
            <img
              alt={props.feature5ImageAlt}
              src={props.feature5ImageSrc}
              className="kernargumente-image5"
            />
            <h2>
              {props.feature5Title ?? (
                <Fragment>
                  <h2 className="kernargumente-text34 thq-heading-2">
                    Feature 5
                  </h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.feature5Description ?? (
                <Fragment>
                  <span className="kernargumente-text30 thq-body-small">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="kernargumente-container8 thq-card">
            <img
              alt={props.feature6ImageAlt}
              src={props.feature6ImageSrc}
              className="kernargumente-image6"
            />
            <h2>
              {props.feature6Title ?? (
                <Fragment>
                  <h2 className="kernargumente-text35 thq-heading-2">
                    Feature 6
                  </h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.feature6Description ?? (
                <Fragment>
                  <span className="kernargumente-text32 thq-body-small">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

Kernargumente.defaultProps = {
  feature2ImageSrc:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/84ec08e8-34e9-42c7-9445-d2806d156403/fac575ac-7a41-484f-b7ac-875042de11f8?org_if_sml=1&force_format=original',
  content1: undefined,
  feature6ImageSrc:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/84ec08e8-34e9-42c7-9445-d2806d156403/fac575ac-7a41-484f-b7ac-875042de11f8?org_if_sml=1&force_format=original',
  feature6ImageAlt: 'Feature 1',
  feature2Description: undefined,
  feature3ImageAlt: 'image',
  feature3Description: undefined,
  feature1ImageSrc:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/84ec08e8-34e9-42c7-9445-d2806d156403/fac575ac-7a41-484f-b7ac-875042de11f8?org_if_sml=1&force_format=original',
  feature4Description: undefined,
  feature1ImageAlt: 'image',
  feature2Title: undefined,
  feature2ImageAlt: 'image',
  feature4ImageAlt: 'image',
  feature3Title: undefined,
  feature5Description: undefined,
  feature4ImageSrc:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/84ec08e8-34e9-42c7-9445-d2806d156403/fac575ac-7a41-484f-b7ac-875042de11f8?org_if_sml=1&force_format=original',
  feature5ImageAlt: 'image',
  feature5ImageSrc:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/84ec08e8-34e9-42c7-9445-d2806d156403/fac575ac-7a41-484f-b7ac-875042de11f8?org_if_sml=1&force_format=original',
  feature1Title: undefined,
  feature6Description: undefined,
  heading1: undefined,
  feature5Title: undefined,
  feature3ImageSrc:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/84ec08e8-34e9-42c7-9445-d2806d156403/fac575ac-7a41-484f-b7ac-875042de11f8?org_if_sml=1&force_format=original',
  feature6Title: undefined,
  feature1Description: undefined,
  feature4Title: undefined,
}

Kernargumente.propTypes = {
  feature2ImageSrc: PropTypes.string,
  content1: PropTypes.element,
  feature6ImageSrc: PropTypes.string,
  feature6ImageAlt: PropTypes.string,
  feature2Description: PropTypes.element,
  feature3ImageAlt: PropTypes.string,
  feature3Description: PropTypes.element,
  feature1ImageSrc: PropTypes.string,
  feature4Description: PropTypes.element,
  feature1ImageAlt: PropTypes.string,
  feature2Title: PropTypes.element,
  feature2ImageAlt: PropTypes.string,
  feature4ImageAlt: PropTypes.string,
  feature3Title: PropTypes.element,
  feature5Description: PropTypes.element,
  feature4ImageSrc: PropTypes.string,
  feature5ImageAlt: PropTypes.string,
  feature5ImageSrc: PropTypes.string,
  feature1Title: PropTypes.element,
  feature6Description: PropTypes.element,
  heading1: PropTypes.element,
  feature5Title: PropTypes.element,
  feature3ImageSrc: PropTypes.string,
  feature6Title: PropTypes.element,
  feature1Description: PropTypes.element,
  feature4Title: PropTypes.element,
}

export default Kernargumente
