import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './pricing.css'

const Pricing = (props) => {
  return (
    <div
      id="pricing"
      className={`pricing-pricing3 thq-section-padding ${props.rootClassName} `}
    >
      <div className="pricing-max-width thq-section-max-width">
        <div className="pricing-section-title">
          <div
            data-thq="slider"
            data-loop="true"
            data-autoplay="true"
            data-navigation="false"
            data-pagination="true"
            data-pause-autoplay-on-mouse-enter="true"
            data-disable-autoplay-on-interaction="false"
            className="pricing-slider swiper"
          >
            <div data-thq="slider-wrapper" className="swiper-wrapper">
              <div
                data-thq="slider-slide"
                className="pricing-slider-slide1 swiper-slide"
              ></div>
              <div
                data-thq="slider-slide"
                className="pricing-slider-slide2 swiper-slide"
              ></div>
              <div
                data-thq="slider-slide"
                className="pricing-slider-slide3 swiper-slide"
              ></div>
              <div
                data-thq="slider-slide"
                className="pricing-slider-slide4 swiper-slide"
              ></div>
              <div
                data-thq="slider-slide"
                className="pricing-slider-slide5 swiper-slide"
              ></div>
            </div>
            <div
              data-thq="slider-pagination"
              className="pricing-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
            >
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet swiper-pagination-bullet-active"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
            </div>
            <div
              data-thq="slider-button-prev"
              className="pricing-slider-button-prev swiper-button-prev"
            ></div>
            <div
              data-thq="slider-button-next"
              className="pricing-slider-button-next swiper-button-next"
            ></div>
          </div>
          <div className="pricing-content1">
            <h2>
              {props.heading1 ?? (
                <Fragment>
                  <h2 className="pricing-text28 thq-heading-2">Pricing plan</h2>
                </Fragment>
              )}
            </h2>
            <p>
              {props.content2 ?? (
                <Fragment>
                  <p className="pricing-text31 thq-body-large">
                    <span>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </p>
                </Fragment>
              )}
            </p>
          </div>
        </div>
        <div className="pricing-content2 thq-card">
          <div className="pricing-price-title">
            <img
              alt={props.imageAlt5}
              src={props.imageSrc5}
              className="pricing-image1"
            />
            <p>
              {props.plan1Detail ?? (
                <Fragment>
                  <p className="pricing-text21 thq-body-large">
                    Lorem ipsum dolor sit amet
                  </p>
                </Fragment>
              )}
            </p>
          </div>
          <div className="thq-divider-horizontal"></div>
          <div className="pricing-price">
            <h3>
              {props.plan1Price ?? (
                <Fragment>
                  <h3 className="pricing-text23 thq-heading-3">
                    <span className="pricing-text24">ab </span>
                    <span>€80</span>
                    <span className="pricing-text26">/Nacht</span>
                  </h3>
                </Fragment>
              )}
            </h3>
            <p>
              {props.plan1Yearly ?? (
                <Fragment>
                  <p className="pricing-text20 thq-body-large">
                    Buchbar ab 5 Tagen.
                  </p>
                </Fragment>
              )}
            </p>
          </div>
          <a href="#kontakt" className="pricing-button thq-button-filled">
            <span>
              {props.plan1Action ?? (
                <Fragment>
                  <span className="pricing-text34 thq-body-small">
                    Anfrage Stellen
                  </span>
                </Fragment>
              )}
            </span>
          </a>
          <div className="thq-divider-horizontal"></div>
          <div className="pricing-list">
            <div className="pricing-list-item1">
              <img
                alt={props.imageAlt}
                src={props.imageSrc}
                className="pricing-image2 thq-icon-small"
              />
              <span>
                {props.plan1Feature1 ?? (
                  <Fragment>
                    <span className="pricing-text29 thq-body-small">
                      Feature text goes here
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
            <div className="pricing-list-item2">
              <img
                alt={props.imageAlt1}
                src={props.imageSrc1}
                className="pricing-image3 thq-icon-small"
              />
              <span>
                {props.plan1Feature2 ?? (
                  <Fragment>
                    <span className="pricing-text22 thq-body-small">
                      Feature text goes here
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
            <div className="pricing-list-item3">
              <img
                alt={props.imageAlt2}
                src={props.imageSrc2}
                className="pricing-image4 thq-icon-small"
              />
              <span>
                {props.plan1Feature3 ?? (
                  <Fragment>
                    <span className="pricing-text30 thq-body-small">
                      Feature text goes here
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
            <div className="pricing-list-item4">
              <img
                alt={props.imageAlt3}
                src={props.imageSrc3}
                className="pricing-image5 thq-icon-small"
              />
              <span>
                {props.plan1Feature4 ?? (
                  <Fragment>
                    <span className="pricing-text27 thq-body-small">
                      Feature text goes here
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Pricing.defaultProps = {
  imageSrc: '/Icons/noun-bedroom-5156534.svg',
  plan1Yearly: undefined,
  imageAlt2: 'image',
  imageSrc3: '/Icons/noun-sea-1755215.svg',
  imageAlt5: 'image',
  imageSrc1: '/Icons/noun-car-parking-5446083.svg',
  plan1Detail: undefined,
  plan1Feature2: undefined,
  plan1Price: undefined,
  plan1Feature4: undefined,
  heading1: undefined,
  plan1Feature1: undefined,
  imageAlt1: 'image',
  imageSrc5: '/logo.svg',
  imageSrc2: '/Icons/noun-sizes-844736.svg',
  imageAlt: 'image',
  imageAlt3: 'image',
  rootClassName: '',
  plan1Feature3: undefined,
  content2: undefined,
  imageSrc4: '3d6e19c4-b2d6-4904-9d30-2869c4d713f6',
  plan1Action: undefined,
}

Pricing.propTypes = {
  imageSrc: PropTypes.string,
  plan1Yearly: PropTypes.element,
  imageAlt2: PropTypes.string,
  imageSrc3: PropTypes.string,
  imageAlt5: PropTypes.string,
  imageSrc1: PropTypes.string,
  plan1Detail: PropTypes.element,
  plan1Feature2: PropTypes.element,
  plan1Price: PropTypes.element,
  plan1Feature4: PropTypes.element,
  heading1: PropTypes.element,
  plan1Feature1: PropTypes.element,
  imageAlt1: PropTypes.string,
  imageSrc5: PropTypes.string,
  imageSrc2: PropTypes.string,
  imageAlt: PropTypes.string,
  imageAlt3: PropTypes.string,
  rootClassName: PropTypes.string,
  plan1Feature3: PropTypes.element,
  content2: PropTypes.element,
  imageSrc4: PropTypes.string,
  plan1Action: PropTypes.element,
}

export default Pricing
