import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './anreise.css'

const Anreise = (props) => {
  return (
    <div className="anreise-container1 thq-section-padding">
      <div className="anreise-max-width thq-section-max-width">
        <div className="anreise-content1 thq-flex-row">
          <div className="anreise-content2">
            <h1>
              {props.heading ?? (
                <Fragment>
                  <h1 className="anreise-text5">Headings</h1>
                </Fragment>
              )}
            </h1>
            <div className="anreise-content3">
              <img
                alt={props.imageAlt}
                src={props.imageSrc}
                className="anreise-image"
              />
              <div className="anreise-contact-info">
                <h3 className="thq-heading-3">Alicante Airport</h3>
                <p>
                  {props.content2 ?? (
                    <Fragment>
                      <p className="anreise-text4 thq-body-large">
                        Der Flughafen von Alicante ist 34 km entfernt und mit
                        dem Auto in ca. 30 Minuten zu erreichen.
                      </p>
                    </Fragment>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="anreise-container2">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m22!1m8!1m3!1d200753.58474357912!2d-0.6932628!3d38.1686739!3m2!1i1024!2i768!4f13.1!4m11!3e0!4m5!1s0xd624ac64d10da33%3A0xdcb245de25b9bef1!2sFlughafen%20Alicante%20(ALC)%2C%20Av.%20de%20l'Altet%2C%2003195%20Elx%2C%20Alicante%2C%20Spanien!3m2!1d38.284924!2d-0.5622132!4m3!3m2!1d38.062509899999995!2d-0.6549541999999999!5e0!3m2!1sde!2sde!4v1727360496570!5m2!1sde!2sde"
              width="500"
              height="450"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              allowfullscreen
              className="anreise-iframe"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}

Anreise.defaultProps = {
  imageAlt: 'image',
  content2: undefined,
  heading: undefined,
  imageSrc: '/Icons/noun-airport-7202218.svg',
  location1GoogleMap:
    'https://www.google.com/maps/embed?pb=!1m22!1m8!1m3!1d200753.58474357912!2d-0.6932628!3d38.1686739!3m2!1i1024!2i768!4f13.1!4m11!3e0!4m5!1s0xd624ac64d10da33%3A0xdcb245de25b9bef1!2sFlughafen%20Alicante%20(ALC)%2C%20Av.%20de%20l&#39;Altet%2C%2003195%20Elx%2C%20Alicante%2C%20Spanien!3m2!1d38.284924!2d-0.5622132!4m3!3m2!1d38.062509899999995!2d-0.6549541999999999!5e0!3m2!1sde!2sde!4v1727360496570!5m2!1sde!2sde',
}

Anreise.propTypes = {
  imageAlt: PropTypes.string,
  content2: PropTypes.element,
  heading: PropTypes.element,
  imageSrc: PropTypes.string,
  location1GoogleMap: PropTypes.string,
}

export default Anreise
