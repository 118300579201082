import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Hero4 from '../components/hero4'
import Pricing from '../components/pricing'
import Ausstattung from '../components/ausstattung'
import CTA from '../components/cta'
import Kernargumente from '../components/kernargumente'
import Gallery7 from '../components/gallery7'
import Sehenswuerdigkeiten from '../components/sehenswuerdigkeiten'
import Anreise from '../components/anreise'
import ContactForm from '../components/contact-form'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Ferienwohnung Costa Blanca</title>
        <meta
          name="description"
          content="Unsere Ferienwohnung in Portico Mar an der Costa Blanca bietet Ihnen auf 80 m² eine umfangreiche Ausstattung, die auch längere Aufenthalte angenehm gestaltet."
        />
        <meta property="og:title" content="Ferienwohnung Costa Blanca" />
        <meta
          property="og:description"
          content="Unsere Ferienwohnung in Portico Mar an der Costa Blanca bietet Ihnen auf 80 m² eine umfangreiche Ausstattung, die auch längere Aufenthalte angenehm gestaltet."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/54bdcf4b-f76c-48c3-af25-f6e30758d129/8bd79af1-c49d-4e46-a714-896e7351d314?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Hero4
        action1={
          <Fragment>
            <span className="home-text10 thq-body-small">Costa Blanca</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="home-text11 thq-body-small">Ausstattung</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text12 thq-body-large">
              Unsere schöne Wohnung in der Ferienanlage Portico Mar direkt an
              der Costa Blanca in Spanien bietet Ihnen auf 80 m² eine
              umfangreiche Ausstattung, die auch längere Aufenthalte angenehm
              gestaltet.
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text13 thq-heading-1 shadowhandW">
              Ferienwohnung mit Meerblick
            </span>
          </Fragment>
        }
        video1Src="/herovideo2.mp4"
        rootClassName="hero4root-class-name"
      ></Hero4>
      <Pricing
        content2={
          <Fragment>
            <span className="home-text14 thq-body-large">
              Erleben Sie in unserer Unterkunft mediterranes Flair zum
              Wohlfühlen und Entspannen.
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text15 thq-heading-2">
              Willkommen im Urlaub!
            </span>
          </Fragment>
        }
        imageSrc2="/Icons/noun-capacity-2526128.svg"
        imageSrc5="/v4.svg"
        plan1Price={
          <Fragment>
            <span className="home-text16 thq-heading-3">
              <span className="home-text17">ab </span>
              <span>€85 </span>
              <span className="home-text19">/Nacht</span>
            </span>
          </Fragment>
        }
        plan1Action={
          <Fragment>
            <span className="home-text20 thq-body-small">
              Verfügbarkeit Anfragen
            </span>
          </Fragment>
        }
        plan1Detail={
          <Fragment>
            <span className="home-text21 thq-body-large">
              Buchen Sie jetzt Ihren Urlaub an der Costa Blanca
            </span>
          </Fragment>
        }
        plan1Yearly={
          <Fragment>
            <span className="home-text22 thq-body-large">
              Buchbar ab 5 Nächten.
            </span>
          </Fragment>
        }
        plan1Feature1={
          <Fragment>
            <span className="home-text23 thq-body-small">
              Zwei Schlafzimmer max. 5 Personen
            </span>
          </Fragment>
        }
        plan1Feature2={
          <Fragment>
            <span className="home-text24 thq-body-small">
              Tiefgaragenplatz inklusive
            </span>
          </Fragment>
        }
        plan1Feature3={
          <Fragment>
            <span className="home-text25 thq-body-small">
              80 m² auf drei Etagen
            </span>
          </Fragment>
        }
        plan1Feature4={
          <Fragment>
            <span className="home-text26 thq-body-small">
              400m bis zum feinen Sandstrand
            </span>
          </Fragment>
        }
        rootClassName="pricingroot-class-name"
      ></Pricing>
      <Ausstattung
        text1={
          <Fragment>
            <span className="home-text27"> Vollbild Ansicht</span>
          </Fragment>
        }
        text2={
          <Fragment>
            <span className="home-text28 thq-heading-3">
              Ansichtskarte Wohnkomplex &quot;Portico Mar&quot;
            </span>
          </Fragment>
        }
        heading={
          <Fragment>
            <span className="home-text29 thq-heading-2">Ausstattung</span>
          </Fragment>
        }
        rootClassName="ausstattungroot-class-name"
      ></Ausstattung>
      <CTA
        action1={
          <Fragment>
            <span className="home-text30">Jetzt Anfragen</span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text31 thq-heading-2 shadowhand">
              Freuen Sie sich auf die Costa Blanca
            </span>
          </Fragment>
        }
        backgroundImageSrc="/whatsapp%20image%202024-10-15%20at%2018.36.1ff1-1500w.webp"
      ></CTA>
      <Kernargumente
        content1={
          <Fragment>
            <span className="home-text32 thq-body-small">
              Was Ihren Urlaub in unserer Ferienwohnung so besonders macht:
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text33 thq-heading-2">
              Worauf Sie sich freuen können
            </span>
          </Fragment>
        }
        feature1Title={
          <Fragment>
            <span className="home-text34 thq-heading-2">
              Erholung der Extraklasse
            </span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="home-text35 thq-heading-2">Gutes Wetter</span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <span className="home-text36 thq-heading-2">
              Premium Unterkunft
            </span>
          </Fragment>
        }
        feature4Title={
          <Fragment>
            <span className="home-text37 thq-heading-2">Strandleben</span>
          </Fragment>
        }
        feature5Title={
          <Fragment>
            <span className="home-text38 thq-heading-2">Sicherheit</span>
          </Fragment>
        }
        feature6Title={
          <Fragment>
            <span className="home-text39 thq-heading-2">
              Poolanlage direkt vor der Wohnungstür
            </span>
          </Fragment>
        }
        feature1ImageSrc="/Icons/noun-health-7220553.svg"
        feature2ImageSrc="/Icons/noun-weather-7103924.svg"
        feature3ImageSrc="/Icons/noun-house-7189818.svg"
        feature4ImageSrc="/Icons/noun-beach-beach-3510451.svg"
        feature5ImageSrc="/Icons/noun-security-7094578.svg"
        feature6ImageSrc="/Icons/noun-pool-7183779.svg"
        feature1Description={
          <Fragment>
            <span className="home-text40 thq-body-small">
              Die Wohnung ist sehr schön ruhig gelegen und unmittelbar am Strand
              der Costa Blanca. Unsere Ferienwohnung befindet sich in einer der
              gesündesten Regionen der Welt (laut WHO), ideal für Menschen mit
              Rheuma oder Psoriasis.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text41 thq-body-small">
              Die Wassertemperatur beträgt im August ca. 26 °C und in den
              Wintermonaten ca. 18 °C. Das ganze Jahr über herrschen angenehm
              milde Temperaturen mit geringer Luftfeuchtigkeit. Die Sonne
              scheint an mehr als 300 Tagen im Jahr. An den breiten Sandstränden
              kann man sich das ganze Jahr über sonnen.
            </span>
          </Fragment>
        }
        feature3Description={
          <Fragment>
            <span className="home-text42 thq-body-small">
              Von der Terrasse, vom Balkon und von der großen Dachterrasse aus
              haben Sie einen herrlichen Blick direkt auf das Meer. Die
              Ferienanlage Portico Mar wurde um das Jahr 2000 erbaut und ist
              sehr gepflegt. Sie verfügt über drei Swimmingpools, einen
              mediterranen Innenhof und ein Restaurant.
            </span>
          </Fragment>
        }
        feature4Description={
          <Fragment>
            <span className="home-text43 thq-body-small">
              Unser Apartment liegt unmittelbar am Strand von El Moncayo. Im
              Sommer gibt es dort eine Strandbar und sie können sogenannte
              Daybeds mieten. Der Strand ist breit, feinsandig, sauber und
              gepflegt. Das Strandleben an der Costa Blanca ist unkomliziert:
              Sie können Ihre, bzw. unsere Liege oder eine Matte einfach zum
              Strand mitnehmen und sich dort drauflegen.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        feature5Description={
          <Fragment>
            <span className="home-text44 thq-body-small">
              <span>
                Die Anlage sowie die Wohnung hat gesicherte Türen und ist nur
                für Eigentümer und Gäste mit eigenem Schlüssel zugänglich.
                (Sicherheitscodes an den Türen des Komplexes der Ferienanlage).
                Ein Wachdienst ist nachts anwesend. Für die sichere und kühle
                Unterbringung Ihres Autos stellen wir Ihnen unseren
                Tiefgaragenstellplatz zur Verfügung.
              </span>
              <br></br>
              <br></br>
            </span>
          </Fragment>
        }
        feature6Description={
          <Fragment>
            <span className="home-text48 thq-body-small">
              Freuen Sie auf eine gepflegte Poolanlage mit 3 Poolbecken und
              einer großzügigen Liegefläche. Sie können sich gerne mit der
              Sonnenliege, die wir Ihnen bereitstellen, dort hinlegen, um z.B.
              im Schatten einer Palme gemütlich ein Buch zu lesen. Die Pools
              werden in den Sommermonaten täglich von einem Bademeister
              beaufsichtigt. Genießen Sie alle Vorteile, den die wenigen
              Schritte von ihrer Haustür bis zu den Pools mit sich bringen.
            </span>
          </Fragment>
        }
      ></Kernargumente>
      <Gallery7
        content1={
          <Fragment>
            <span className="home-text49 thq-body-large">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text50 thq-heading-2">Fotogalerie</span>
          </Fragment>
        }
        image1Src="https://images.unsplash.com/photo-1680538342924-245ca7e3e60c?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIzfHxjb3N0YSUyMGJsYW5jYXxlbnwwfHx8fDE3MjgyMzMxMzF8MA&amp;ixlib=rb-4.0.3&amp;w=1500"
        image2Src="https://images.unsplash.com/photo-1667767336511-5d6addd65d41?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNzIxODAxMHw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
        image3Src="https://images.unsplash.com/photo-1614367990268-6a6dd7ec3782?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEyfHxjb3N0YSUyMGJsYW5jYXxlbnwwfHx8fDE3MjgyMzMxMzF8MA&amp;ixlib=rb-4.0.3&amp;w=1500"
        image4Src="https://images.unsplash.com/photo-1658133775801-24dc24ce4312?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNzIxODAxMXw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
        image5Src="https://images.unsplash.com/photo-1527026201-fd44973af6e7?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNzIxODAxMXw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
        image6Src="https://images.unsplash.com/photo-1554939437-ecc492c67b78?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNzIxODAxMXw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
        image7Src="https://images.unsplash.com/photo-1561369408-1e91d37fd2c5?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNzIxODAxMXw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
        rootClassName="gallery7root-class-name"
      ></Gallery7>
      <Sehenswuerdigkeiten
        text1={
          <Fragment>
            <span className="home-text51">Link Tripadvisor</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text52 thq-body-large">
              Der Playa Moncayo und die Costa Blanca haben Ihnen einiges zu
              bieten.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text53 thq-heading-2">
              Aktivitäten in der Umgebung
            </span>
          </Fragment>
        }
        faq1Question={
          <Fragment>
            <span className="home-text54 thq-body-large">
              Restaurants &amp; Cafés in der Nähe
            </span>
          </Fragment>
        }
        faq2Question={
          <Fragment>
            <span className="home-text55 thq-body-large">
              Strände in der Umgebung
            </span>
          </Fragment>
        }
        faq3Question={
          <Fragment>
            <span className="home-text56 thq-body-large">
              Sehenswürdigkeiten
            </span>
          </Fragment>
        }
        faq4Question={
          <Fragment>
            <span className="home-text57 thq-body-large">Markttag</span>
          </Fragment>
        }
      ></Sehenswuerdigkeiten>
      <Anreise
        heading={
          <Fragment>
            <span className="home-text58">Anreise</span>
          </Fragment>
        }
        content2={
          <Fragment>
            <span className="home-text59 thq-body-large">
              Der Flughafen von Alicante ist 34 km entfernt und mit dem Auto in
              ca. 30 Minuten zu erreichen.
            </span>
          </Fragment>
        }
      ></Anreise>
      <ContactForm
        email={
          <Fragment>
            <span className="home-text60 thq-body-small">
              +49 (0) 517 0173 899
            </span>
          </Fragment>
        }
        text1={
          <Fragment>
            <span className="home-text61">
              Nutzen Sie das Kontaktformular oder schreiben Sie uns eine E-Mail:
              kontakt@fewo-costa-blanca.com
            </span>
          </Fragment>
        }
        text2={
          <Fragment>
            <span className="home-text62">*Erforderliche Felder</span>
          </Fragment>
        }
        email1={
          <Fragment>
            <span className="home-text63 thq-body-small">kontakt@fewo.de</span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text64">Kontaktformular</span>
          </Fragment>
        }
        submitAction={
          <Fragment>
            <span className="home-text65 thq-body-small">Nachricht Senden</span>
          </Fragment>
        }
      ></ContactForm>
      <Footer
        logoSrc="/v4.svg"
        privacyLink={
          <Fragment>
            <span className="home-text66 thq-body-small">Impressum</span>
          </Fragment>
        }
      ></Footer>
    </div>
  )
}

export default Home
