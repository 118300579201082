import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './sehenswuerdigkeiten.css'

const Sehenswuerdigkeiten = (props) => {
  const [faq4Visible, setFaq4Visible] = useState(false)
  const [faq1Visible, setFaq1Visible] = useState(false)
  const [faq3Visible, setFaq3Visible] = useState(false)
  const [faq2Visible, setFaq2Visible] = useState(false)
  const [faq5Visible, setFaq5Visible] = useState(false)
  return (
    <div className="sehenswuerdigkeiten-faq8 thq-section-padding">
      <div className="sehenswuerdigkeiten-max-width thq-section-max-width">
        <div className="sehenswuerdigkeiten-container10 thq-flex-column">
          <div className="sehenswuerdigkeiten-section-title thq-flex-column thq-section-max-width">
            <div className="sehenswuerdigkeiten-content">
              <h2>
                {props.heading1 ?? (
                  <Fragment>
                    <h2 className="sehenswuerdigkeiten-text77 thq-heading-2">
                      Default value
                    </h2>
                  </Fragment>
                )}
              </h2>
              <p>
                {props.content1 ?? (
                  <Fragment>
                    <p className="sehenswuerdigkeiten-text72 thq-body-large">
                      Default value
                    </p>
                  </Fragment>
                )}
              </p>
            </div>
          </div>
          <div className="thq-flex-column thq-section-max-width">
            <div className="thq-divider-horizontal"></div>
            <div className="sehenswuerdigkeiten-faq1">
              <div
                onClick={() => setFaq1Visible(!faq1Visible)}
                className="sehenswuerdigkeiten-trigger1"
              >
                <p>
                  {props.faq1Question ?? (
                    <Fragment>
                      <p className="sehenswuerdigkeiten-text73 thq-body-large">
                        Default value
                      </p>
                    </Fragment>
                  )}
                </p>
                <div className="sehenswuerdigkeiten-icons-container1">
                  {!faq1Visible && (
                    <div>
                      <svg
                        viewBox="0 0 1024 1024"
                        className="sehenswuerdigkeiten-icon10"
                      >
                        <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                      </svg>
                    </div>
                  )}
                  {faq1Visible && (
                    <div>
                      <svg
                        viewBox="0 0 1024 1024"
                        className="sehenswuerdigkeiten-icon12"
                      >
                        <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq1Visible && (
                <div className="sehenswuerdigkeiten-container13">
                  <span className="thq-body-small">
                    <span> • Restaurant Portico Mar 100 m</span>
                    <br></br>
                    <span>• Restaurante El Barco 1,6 km</span>
                    <br></br>
                    <span>• Restaurante Casablanca 1,6 km</span>
                  </span>
                </div>
              )}
            </div>
            <div className="thq-divider-horizontal"></div>
            <div className="sehenswuerdigkeiten-faq2">
              <div
                onClick={() => setFaq2Visible(!faq2Visible)}
                className="sehenswuerdigkeiten-trigger2"
              >
                <p>
                  {props.faq2Question ?? (
                    <Fragment>
                      <p className="sehenswuerdigkeiten-text76 thq-body-large">
                        Default value
                      </p>
                    </Fragment>
                  )}
                </p>
                <div className="sehenswuerdigkeiten-icons-container2">
                  {!faq2Visible && (
                    <div>
                      <svg
                        viewBox="0 0 1024 1024"
                        className="sehenswuerdigkeiten-icon14"
                      >
                        <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                      </svg>
                    </div>
                  )}
                  {faq2Visible && (
                    <div>
                      <svg
                        viewBox="0 0 1024 1024"
                        className="sehenswuerdigkeiten-icon16"
                      >
                        <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq2Visible && (
                <div className="sehenswuerdigkeiten-container16">
                  <span className="thq-body-small">
                    <span> • Playa El Moncayo 350 m</span>
                    <br></br>
                    <span>• Platja La Roqueta 1,9 km</span>
                    <br></br>
                    <span>• Platja Centre 2,2 km</span>
                    <br></br>
                    <span>• Playa Les Ortiques 3,9 km</span>
                  </span>
                </div>
              )}
            </div>
            <div className="thq-divider-horizontal"></div>
            <div className="sehenswuerdigkeiten-faq3">
              <div
                onClick={() => setFaq3Visible(!faq3Visible)}
                className="sehenswuerdigkeiten-trigger3"
              >
                <p>
                  {props.faq3Question ?? (
                    <Fragment>
                      <p className="sehenswuerdigkeiten-text75 thq-body-large">
                        Default value
                      </p>
                    </Fragment>
                  )}
                </p>
                <div className="sehenswuerdigkeiten-icons-container3">
                  {!faq3Visible && (
                    <div>
                      <svg
                        viewBox="0 0 1024 1024"
                        className="sehenswuerdigkeiten-icon18"
                      >
                        <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                      </svg>
                    </div>
                  )}
                  {faq3Visible && (
                    <div>
                      <svg
                        viewBox="0 0 1024 1024"
                        className="sehenswuerdigkeiten-icon20"
                      >
                        <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq3Visible && (
                <div className="sehenswuerdigkeiten-container19">
                  <span className="thq-body-small">
                    <span className="sehenswuerdigkeiten-text27">
                      Cartagena
                    </span>
                    <br></br>
                    <span>
                      Cartagena ist eine lebhafte Hafenstadt an der
                      Mittelmeerküste Spaniens. Mit ihrer reichen Geschichte,
                      die bis in die Antike zurückreicht, bietet die Stadt
                      beeindruckende Sehenswürdigkeiten wie römische Ruinen und
                      eine wunderschöne Altstadt. Der Hafen ist nicht nur ein
                      wichtiger Handelsplatz, sondern auch ein beliebter
                      Anlaufpunkt für Kreuzfahrtschiffe.
                    </span>
                    <br></br>
                    <br></br>
                    <span className="sehenswuerdigkeiten-text32">
                      Guadalest
                    </span>
                    <br></br>
                    <span>
                      Guadalest ist ein malerisches Bergdorf, das hoch in den
                      Bergen der Costa Blanca thront. Bekannt für seine
                      atemberaubenden Ausblicke und die gut erhaltene Burg,
                      zieht es Besucher mit charmanten, engen Gassen und
                      traditionellen weißgetünchten Häusern an. Die umliegende
                      Natur und der nahegelegene Stausee machen es zu einem
                      idealen Ziel für Naturliebhaber.
                    </span>
                    <br></br>
                    <br></br>
                    <span className="sehenswuerdigkeiten-text37">
                      Paseo Marítimo de Guardemar
                    </span>
                    <br></br>
                    <span>
                      Die Strandpromenade von Guardemar ist ein beliebter Ort
                      für Spaziergänge und Entspannung am Meer. Gesäumt von
                      Palmen und mit direktem Zugang zu den feinen Sandstränden,
                      bietet sie zahlreiche Cafés und Restaurants, in denen man
                      die mediterrane Küche genießen kann. Ideal für einen
                      sonnigen Tag am Strand!
                    </span>
                    <br></br>
                    <br></br>
                    <span className="sehenswuerdigkeiten-text42">
                      Mercadillo Campo de Guardemar de Segura
                    </span>
                    <br></br>
                    <span>
                      Der Bauernmarkt in Guardemar de Segura, der jeden Sonntag
                      stattfindet, ist ein wahres Erlebnis für Feinschmecker und
                      Liebhaber lokaler Produkte. Hier findet man frisches Obst,
                      Gemüse, handwerkliche Spezialitäten und vieles mehr. Der
                      Markt bietet nicht nur tolle Einkaufsmöglichkeiten,
                      sondern auch ein authentisches Flair und die Möglichkeit,
                      mit Einheimischen ins Gespräch zu kommen.
                    </span>
                    <br></br>
                    <br></br>
                    <span className="sehenswuerdigkeiten-text47">
                      Reina Sofia Park
                    </span>
                    <br></br>
                    <span>
                      Der Reina Sofia Park ist eine grüne Oase im Herzen von
                      Guardemar. Mit schönen Spazierwegen, Spielplätzen und
                      schattenspendenden Bäumen ist er ein perfekter Ort für
                      Familien und Erholungssuchende. Hier kann man entspannen,
                      picknicken oder einfach die Natur genießen.
                    </span>
                    <br></br>
                    <br></br>
                    <span className="sehenswuerdigkeiten-text52">
                      Parc Alfonso XIII
                    </span>
                    <br></br>
                    <span>
                      Der Parc Alfonso XIII ist ein charmantes
                      Naherholungsgebiet, das zum Verweilen und Entspannen
                      einlädt. Mit gepflegten Gärten, Teichanlagen und
                      schattigen Plätzen ist er ein beliebter Treffpunkt für
                      Einheimische und Besucher. Ideal für einen ruhigen
                      Nachmittag in der Natur.
                    </span>
                    <br></br>
                    <br></br>
                    <span className="sehenswuerdigkeiten-text57">
                      Laguna de la Mata
                    </span>
                    <br></br>
                    <span>
                      Die Laguna de la Mata ist ein beeindruckendes
                      Naturreservat, das für seine einzigartige Flora und Fauna
                      bekannt ist. Die Lagune bietet zahlreiche Wander- und
                      Radwege, die durch malerische Landschaften führen.
                      Vogelliebhaber kommen hier besonders auf ihre Kosten, da
                      viele seltene Arten beobachtet werden können. Ein Ort der
                      Ruhe und des Naturschutzes!
                    </span>
                    <br></br>
                    <br></br>
                    <span className="sehenswuerdigkeiten-text62">
                      Flamingos an der Costa Blanca
                    </span>
                    <br></br>
                    <span>
                      An der Costa Blanca sind Flamingos ein faszinierendes
                      Highlight! Diese eleganten Vögel mit ihrem rosafarbenen
                      Gefieder und den langen Beinen sind häufig in den
                      Salzwiesen und Lagunen, wie im Naturpark La
                      Mata-Torrevieja, zu beobachten. Ideal für Naturliebhaber
                      und Fotografen, bieten sie ein unvergessliches Erlebnis in
                      der malerischen Landschaft der Region. Verpassen Sie nicht
                      die Gelegenheit, diese beeindruckenden Vögel in ihrem
                      natürlichen Habitat zu sehen!
                    </span>
                    <br></br>
                  </span>
                </div>
              )}
            </div>
            <div className="thq-divider-horizontal"></div>
            <div className="sehenswuerdigkeiten-faq4">
              <div
                onClick={() => setFaq4Visible(!faq4Visible)}
                className="sehenswuerdigkeiten-trigger4"
              >
                <p>
                  {props.faq4Question ?? (
                    <Fragment>
                      <p className="sehenswuerdigkeiten-text74 thq-body-large">
                        Default value
                      </p>
                    </Fragment>
                  )}
                </p>
                <div className="sehenswuerdigkeiten-icons-container4">
                  {!faq4Visible && (
                    <div>
                      <svg
                        viewBox="0 0 1024 1024"
                        className="sehenswuerdigkeiten-icon22"
                      >
                        <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                      </svg>
                    </div>
                  )}
                  {faq4Visible && (
                    <div>
                      <svg
                        viewBox="0 0 1024 1024"
                        className="sehenswuerdigkeiten-icon24"
                      >
                        <path d="M316 658l-60-60 256-256 256 256-60 60-196-196z"></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              {faq4Visible && (
                <div className="sehenswuerdigkeiten-container22">
                  <span className="thq-body-small">
                    <span>
                      Sonntags Markt 9:00 Uhr – 13:00 Uhr Mercadillo Campo de
                      Guardemar del Segwa
                    </span>
                    <br></br>
                    <br></br>
                    <span>
                      Der Bauernmarkt in Guardemar de Segura, der jeden Sonntag
                      stattfindet, ist ein wahres Erlebnis für Feinschmecker und
                      Liebhaber lokaler Produkte. Hier findet man frisches Obst,
                      Gemüse, handwerkliche Spezialitäten und vieles mehr. Der
                      Markt bietet nicht nur tolle Einkaufsmöglichkeiten,
                      sondern auch ein authentisches Flair und die Möglichkeit,
                      mit Einheimischen ins Gespräch zu kommen.
                    </span>
                    <br></br>
                  </span>
                  <a
                    href="https://www.tripadvisor.de/Attraction_Review-g652147-d8727817-Reviews-Mercadillo_Campo_de_Guardamar_del_Segura-Guardamar_del_Segura_Costa_Blanca_Provin.html"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="sehenswuerdigkeiten-link"
                  >
                    {props.text1 ?? (
                      <Fragment>
                        <span className="sehenswuerdigkeiten-text78">
                          Link Tripadvisor
                        </span>
                      </Fragment>
                    )}
                  </a>
                </div>
              )}
            </div>
            <div className="thq-divider-horizontal"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

Sehenswuerdigkeiten.defaultProps = {
  content1: undefined,
  faq1Question: undefined,
  faq4Question: undefined,
  faq3Question: undefined,
  faq2Question: undefined,
  heading1: undefined,
  text1: undefined,
}

Sehenswuerdigkeiten.propTypes = {
  content1: PropTypes.element,
  faq1Question: PropTypes.element,
  faq4Question: PropTypes.element,
  faq3Question: PropTypes.element,
  faq2Question: PropTypes.element,
  heading1: PropTypes.element,
  text1: PropTypes.element,
}

export default Sehenswuerdigkeiten
